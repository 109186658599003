import { Theme } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { unexpectedTypeError } from '../../util/unexpectedTypeError';

const colours = {
  blueBase: '#039be0',
  blueDark: '#01579b',
  blueLight: '#4fc3f7',
  grayBase: '#303030',
  grayDark: '#212121',
  grayLight: '#424242',
  greenBase: '#2da86a',
  orangeBase: '#f79420',
  redBase: '#f06840',
  whiteBase: '#ffffff',
  whiteDisabled: 'rgba(255, 255, 255, 0.5)',
  whiteDivider: 'rgba(255, 255, 255, 0.12)',
  whiteSecondary: 'rgba(255, 255, 255, 0.7)',
  blackBase: 'rgba(0,0,0,0.87)',
  blackDisabled: 'rgba(0,0,0,0.38)',
  blackDivider: 'rgba(0,0,0,0.12)',
  blackSecondary: 'rgba(0,0,0,0.60)',
};

export type VisualisationColours = Readonly<{
  badBot: string;
  goodBot: string;
  human: string;
  whitelisted: string;
  total: string;
}>;

const visualisationColors: VisualisationColours = {
  badBot: colours.redBase,
  goodBot: colours.greenBase,
  human: colours.blueBase,
  whitelisted: colours.whiteDisabled,
  total: colours.whiteBase,
};

export type Status = 'default' | 'danger' | 'success' | 'warning' | 'info';

// TODO: Refactor to remove this util as the base MUI theme now includes `error`, `success`, `warning`, and `info` colours
// Relevant issue for scalable implementation https://github.com/mui-org/material-ui/issues/13875#issuecomment-610684644
export const statusPropToColor = (status: Status, theme: Theme): string => {
  switch (status) {
    case 'info':
      return theme.palette.info.main;
    case 'danger':
      return theme.palette.error.main;
    case 'success':
      return theme.palette.success.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'default':
      return theme.palette.text.secondary;
    default:
      throw unexpectedTypeError(status, 'Status');
  }
};

/*
 * This is following the MUI guide for Typescript customisation of theme:
 *
 * - https://material-ui.com/guides/typescript/#customization-of-theme
 * - https://material-ui.com/customization/palette/#custom-palette
 *
 * Basically, it allows us to add our custom status colours to the theme’s Palette
 */
declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    navigation: string;
  }

  interface Palette {
    visualisation: VisualisationColours;
    background: TypeBackground;
  }

  interface PaletteOptions {
    visualisation?: Partial<VisualisationColours>;
    background?: Partial<TypeBackground>;
  }
}

const basePalette: PaletteOptions = {
  primary: {
    light: colours.blueLight,
    main: colours.blueBase,
    dark: colours.blueDark,
  },
  secondary: {
    main: colours.orangeBase,
  },
  error: {
    main: colours.redBase,
  },
  warning: {
    main: colours.orangeBase,
  },
  info: {
    main: colours.blueBase,
  },
  success: {
    main: colours.greenBase,
  },
};

export const darkPalette: PaletteOptions = {
  ...basePalette,
  type: 'dark',
  text: {
    primary: colours.whiteBase,
    secondary: colours.whiteSecondary,
  },
  divider: colours.whiteDivider,
  background: {
    default: colours.grayBase,
    paper: colours.grayLight,
    navigation: colours.grayDark,
  },
  visualisation: {
    badBot: visualisationColors.badBot,
    goodBot: visualisationColors.goodBot,
    human: visualisationColors.human,
    whitelisted: visualisationColors.whitelisted,
    total: visualisationColors.total,
  },
};

export const lightPalette: PaletteOptions = {
  ...basePalette,
  type: 'light',
  text: {
    primary: colours.blackBase,
    secondary: colours.blackSecondary,
  },
  background: {
    navigation: colours.whiteBase,
  },
  divider: colours.blackDivider,
  visualisation: {
    badBot: visualisationColors.badBot,
    goodBot: visualisationColors.goodBot,
    human: visualisationColors.human,
    whitelisted: colours.blackDisabled,
    total: colours.blackBase,
  },
};
