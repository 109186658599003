import normalTraffic from "../data/normal.json";
import normalFraud from "../data/normalGD.json";
import accountTakeover from "../data/accountTakeover.json";
import scraping from "../data/scraping.json";
import swing from "../data/botSwing.json";

export enum TrafficType {
  NORMAL = "normal",
  NORMAL_FRAUD = "normal-godaddy",
  ACCOUNT_TAKEOVER = "accountTakeover",
  SCRAPING = "scraping",
  SWING = "swing", // https://kasada.kasadapolyform.io/?host=becorbot.kasada.io&timePeriod=8
  CUSTOM = "custom",
}

export const getTrafficData = (type: TrafficType) => {
  switch (type) {
    case TrafficType.NORMAL:
      return normalTraffic;
    case TrafficType.NORMAL_FRAUD:
      return normalFraud;
    case TrafficType.ACCOUNT_TAKEOVER:
      return accountTakeover;
    case TrafficType.SCRAPING:
      return scraping;
    case TrafficType.SWING:
      return swing;
    default:
      return normalTraffic;
  }
};
