import { Box, Container, CssBaseline, Divider, Fab, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import './App.css';
import { backgroundPattern } from './components/BackgroundPatterns';
import { tooltipStyles } from './components/charts/utils';
import { Listen } from './components/listen/Listen';
import { getTheme } from './components/theme/kasadaTheme';
import { ThemeProvider } from './components/theme/ThemeProvider';
import logo from './img/bottts.svg';

const GlobalStyle = createGlobalStyle`
  ${tooltipStyles}
  html, body, body > main {
    height: 100%;
  }

  body {
    ${backgroundPattern}
  }
`;

const App = () => (
  <ThemeProvider theme={getTheme('dark')}>
    <CssBaseline/>

    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
            width={1}
            paddingY={8}
            height="100vh"
          >
            <img src={logo} className="App-logo" alt="logo"/>

            <Typography variant="h2" align="center" paragraph style={{ fontWeight: 'normal' }}>
              Botronica
            </Typography>

            <Typography variant="subtitle2" color="textSecondary" align="center">
              Cyberattacks are a silent crime, how can we make them loud enough for all to hear?
            </Typography>

            <Box width={1} display="flex" alignContent="center" justifyContent="center" marginTop={3}>
              <Fab href="#tldr" color="primary" size="small">
                <ExpandMoreIcon/>
              </Fab>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>

    <Divider/>

    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Container maxWidth="sm">
            <Box marginY={8}>
              <Typography variant="h4" id="tldr" paragraph>TL:DR;</Typography>

              <Typography variant="body1" paragraph>
                Botronica began as an internal Kasada hackathon project.
              </Typography>

              <Typography variant="body1" paragraph>
                Our hypothesis was - cyberattacks are a silent crime, how can we make them loud
                enough for all to hear?
              </Typography>

              <Typography variant="body1" paragraph>
                We set out to explore the sonification of our attack data in order to create unique
                soundscapes that can allow us to experience these attacks from a different
                perspective compared to traditional data visualisation.
              </Typography>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Container>

    <Listen/>

    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Container maxWidth="sm">
            <Box marginY={8}>
              <Typography variant="h4" paragraph>Development</Typography>

              <Typography variant="body1" paragraph>
                We started by doing a deep dive into data sonification research, looking at prior
                work including general sonification, sonification in security, as well as existing
                technologies we could leverage.

                Eventually, after converting the request data into midi format, we used Tone.js to
                play midi in the browser and apply different instruments and audio effects per instrument.
              </Typography>



              <Typography variant="body1" paragraph>
                We built the UI and began to experiment with the playing of various music formats
                using
                custom synths and settings. After multiple interactions, we are able to make the
                traffic data sound tolerable while accentuating the anomalies in the resulting
                soundscape.
              </Typography>
            </Box>

            <Box marginY={8}>
              <Typography variant="h4" paragraph>
                What’s Next?
              </Typography>

              <Typography variant="body1" paragraph>
                We are looking at adding more instruments and genres, while allowing the user to
                customize these on the fly. We are exploring how we can better highlight anomalies as
                we believe Botronica will have practical uses when applied to monitoring and
                detection of malicious traffic.
              </Typography>

              <Typography variant="body1" paragraph>
                Finally, we are now experimenting with audio visualization techniques to produce 3D
                attack simulations. Leveraging tools such as WebGL and D3js to post-process midi
                files will allow us to see cyberattacks as 3D soundscapes, in addition to hearing
                them.
              </Typography>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Container>

    <GlobalStyle/>
  </ThemeProvider>
);

export default App;
