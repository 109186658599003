import { Box, Container, Grid, List, ListSubheader, Paper, Typography } from '@material-ui/core';
import React, { FC, useMemo, useState } from 'react';
import { BotifyV2 } from '../../botify/BotifyV2';
import { getTrafficData, TrafficType } from '../../util/getTrafficData';
import { BoxPattern } from '../BackgroundPatterns';
import { TrafficOverviewChart } from '../charts/TrafficOverviewChart';
import { SongListItem } from './SongListItem';

export const Listen: FC = () => {
  const [trafficType, setTrafficType] = useState<TrafficType>(
    TrafficType.SCRAPING,
  );

  const { timeSeries } = useMemo(() => getTrafficData(trafficType), [
    trafficType,
  ]);

  return (
    <BoxPattern width={1}
                height="100%"
                paddingX={{ xs: 2, sm: 8 }}
                paddingY={8}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" paragraph align="center">
              Listen
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <List subheader={<ListSubheader disableSticky>Songs</ListSubheader>}>
              <Paper style={{ overflow: 'hidden' }}>
                <SongListItem currentTrafficType={trafficType}
                              itemTrafficType={TrafficType.SCRAPING}
                              label="It's beginning to look a lot like Scraping"
                              handleSelection={setTrafficType}
                              divider/>

                <SongListItem currentTrafficType={trafficType}
                              itemTrafficType={TrafficType.NORMAL}
                              label="Jingle Bell Bot"
                              handleSelection={setTrafficType}
                              divider/>

                <SongListItem currentTrafficType={trafficType}
                              itemTrafficType={TrafficType.NORMAL_FRAUD}
                              label="All I Want For Christmas Is Fraud"
                              handleSelection={setTrafficType}
                              divider/>

                <SongListItem currentTrafficType={trafficType}
                              itemTrafficType={TrafficType.ACCOUNT_TAKEOVER}
                              label="Credential Stuffing is here again"
                              handleSelection={setTrafficType}
                />
              </Paper>
            </List>

            <BotifyV2 requestTimeSeries={timeSeries}/>
          </Grid>

          <Grid item xs={12} md={8}>
            <ListSubheader disableSticky>Attack Timeline</ListSubheader>
            <Paper>
              <Box padding={2}>
                <TrafficOverviewChart requestTimeSeries={timeSeries}/>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </BoxPattern>
  );
};
