import { Theme } from '@material-ui/core';
import MUIThemeProvider from '@material-ui/styles/ThemeProvider';
import React, { FC } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

export type ThemeProviderProps = Readonly<{
  theme: Theme;
}>;

export const ThemeProvider: FC<ThemeProviderProps> = ({ theme, children }) => (
  <MUIThemeProvider theme={theme}>
    <StyledComponentsThemeProvider theme={theme}>{children}</StyledComponentsThemeProvider>
  </MUIThemeProvider>
);
