/**
 * NOTE: `unexpectedTypeError` is _not_ a general purpose validation utility!
 *
 * `unexpectedTypeError`'s intended use is exhaustivity checking, which only applies in cases where
 * you’ve _naturally_ narrowed the type of the argument to never. If you need to _cast_ the argument to
 * `never`, you're doing it wrong! :)
 *
 * More generally, the idea is that `unexpectedTypeError` should _never_ be called at runtime for
 * well-typed code. It's meant to catch cases in which someone has lied to the type system (e.g. by
 * abusing the `any` type, using unsafe casting, etc).
 */
export const unexpectedTypeError = (object: never, typeName?: string): TypeError => {
  if (typeName !== undefined) {
    return new TypeError(`Unexpected ${typeName} type for object '${object}'`);
  }

  return new TypeError(`Unexpected type for object '${object}'`);
};
