import { setOptions } from 'highcharts';

setOptions({
  time: {
    useUTC: false,
  },
  lang: {
    thousandsSep: ',',
  },
});
