import { RequestTimelineDataPoint } from '../../components/charts/TrafficOverviewChart';

function scaleBetween(
  unscaledNum: number,
  minAllowed: number,
  maxAllowed: number,
  min: number,
  max: number,
): number {
  return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
}

export const fixRequestSeriesData = (requestTimeSeriesData: RequestTimelineDataPoint[]) => {
  const dataArray = requestTimeSeriesData.reduce((curr, { classifications }) => {
    curr.push(
      classifications.badBot,
      classifications.goodBot,
      classifications.human,
    );
    return curr;
  }, [] as number[]);

  const max = Math.max(...dataArray);
  const min = Math.min(...dataArray);

  return requestTimeSeriesData.map(dataPoint => {
    return ({
      ...dataPoint,
      classifications: {
        ...dataPoint.classifications,
        badBot: scaleBetween(dataPoint.classifications.badBot, 22, 108, min, max),
        human: scaleBetween(dataPoint.classifications.human, 22, 108, min, max),
        goodBot: scaleBetween(dataPoint.classifications.goodBot, 22, 108, min, max),
      },
    });
  });
};
