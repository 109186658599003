import { Options } from 'highcharts';
import { css } from 'styled-components';

export enum ChartLabels {
  BAD_BOT = 'Bad Bot',
  GOOD_BOT = 'Good Bot',
  HUMAN = 'Human',
  WHITELISTED = 'Allowed',
}

enum NumberBarrier {
  THOUSAND = 1000,
  MILLION = 1000000,
  BILLION = 1000000000,
  TRILLION = 1000000000000,
}

/**
 * This method turns things like this: '132.00k'
 * into things like this: '132k'
 */
const removeUnnecessaryDecimals = (stringWithDecimals: string): string =>
  stringWithDecimals.replace(/^[0-9]*(\.0*)[a-zA-Z]?$/, (match, pointWithZeros, offset, wholeString) =>
    wholeString.replace(pointWithZeros, ''),
  );

const makeHumanisedNumber = (
  value: number,
  barrier: NumberBarrier,
  decimalPlaces: number,
  postfix: string,
): string => {
  const numberToDecimalPlaces = Number(value / barrier).toFixed(decimalPlaces);

  return `${removeUnnecessaryDecimals(numberToDecimalPlaces)}${postfix}`;
};

export const humaniseNumberPortal = (value: number, numberOfDecimalPlaces: number): string => {
  if (value >= NumberBarrier.TRILLION) {
    return makeHumanisedNumber(value, NumberBarrier.TRILLION, numberOfDecimalPlaces, 't');
  }

  if (value >= NumberBarrier.BILLION) {
    return makeHumanisedNumber(value, NumberBarrier.BILLION, numberOfDecimalPlaces, 'b');
  }

  if (value >= NumberBarrier.MILLION) {
    return makeHumanisedNumber(value, NumberBarrier.MILLION, numberOfDecimalPlaces, 'm');
  }

  if (value >= NumberBarrier.THOUSAND) {
    return makeHumanisedNumber(value, NumberBarrier.THOUSAND, numberOfDecimalPlaces, 'k');
  }

  return `${value}`;
};

export const baseTooltipOptions: Options['tooltip'] = {
  useHTML: true,
  shape: 'square',
  backgroundColor: 'transparent',
  borderRadius: 2,
  borderWidth: 0,
  padding: 0,
  // These styles only apply to the wrapper element, we need to target the span via CSS selectors
  className: 'globalChartTooltip',
  style: {
    fontSize: '12px',
  },
  shadow: false,
};

// FIXME: Prefer to replace entire tooltip component using portals than global styles
export const tooltipStyles = css`
  .globalChartTooltip {
    // Ensure tooltip renders in front of the chart legend
    z-index: 1;

    // Root element of Tooltip
    & > span {
      border-radius: ${({ theme }): number => theme.shape.borderRadius}px;
      background-color: ${({ theme }): string => theme.palette.background.navigation};
      box-shadow: ${({ theme }): string => theme.shadows[7]};
      overflow: hidden;
      padding: 16px;
    }

    .lineChartTooltip {
      &Header {
        color: ${({ theme }): string => theme.palette.text.secondary};
      }

      &Body {
        margin-top: 8px;
        color: ${({ theme }): string => theme.palette.text.primary};

        &Name {
          padding-left: 4px;
        }

        &Value {
          text-align: right;
          padding-left: 32px;
        }
      }
    }

    .barChartTooltip {
      &Header {
        color: ${({ theme }): string => theme.palette.text.secondary};
      }

      &Body {
        color: ${({ theme }): string => theme.palette.text.primary};
        display: block;
        margin-top: 4px;
        font-size: 14px;
      }
    }
  }
`;
