import "./highchartsGlobal";

import Highcharts, {
  Chart,
  Options,
  Point,
  Series,
  SeriesSplineOptions,
} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isNil, sum } from "ramda";
import React, { FC } from "react";
import { baseTooltipOptions, humaniseNumberPortal } from "./utils";
import { useTheme } from "@material-ui/core";
import { Palette } from "@material-ui/core/styles/createPalette";

export type LineChartDataPoint = Readonly<{
  x: number;
  y: number;
}>;

export type LineChartSeries = Readonly<{
  label: string;
  color: string;
  dataPoints: LineChartDataPoint[];
  zIndex?: number;
}>;

const splineChartSeriesOptions = (
  lineChartSeries: LineChartSeries
): SeriesSplineOptions => ({
  type: "spline",
  color: lineChartSeries.color,
  data: [...lineChartSeries.dataPoints].map((dataPoint) => ({ ...dataPoint })),
  name: lineChartSeries.label,
  lineWidth: 2,
  states: {
    hover: {
      lineWidthPlus: 0,
    },
  },
  marker: {
    enabled: false,
    symbol: "circle",
    states: {
      hover: {
        enabled: false,
      },
    },
  },
  zIndex: lineChartSeries.zIndex,
});

const lineChartBaseOptions = (
  series: LineChartSeries[],
  palette: Palette,
  height?: number,
  yAxisLabel?: string
): Options => ({
  chart: {
    backgroundColor: "transparent",
    spacing: [10, 0, 0, 0],
    animation: true,
    style: {
      fontFamily: "Lato, Helvetica, sans-serif",
    },
    height,
    selectionMarkerFill: `${palette.primary.main}1f`,
  },
  title: {
    style: {
      display: "none",
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: {
        duration: 150,
      },
    },
  },
  xAxis: {
    type: "datetime",
    title: {
      text: null,
    },
    tickLength: 0,
    lineColor: palette.text.secondary,
    dateTimeLabelFormats: {
      day: {
        main: "%d %b",
      },
    },
    labels: {
      style: {
        fontSize: "12px",
        color: palette.text.secondary,
      },
    },
    crosshair: {
      width: 10,
      color: palette.divider,
      zIndex: 5,
    },
  },
  yAxis: {
    title: {
      text: yAxisLabel,
      style: {
        fontSize: "12px",
        lineHeight: "20px",
        color: palette.text.secondary,
      },
    },
    type: "linear",
    gridLineWidth: 0,
    lineWidth: 1,
    lineColor: palette.text.secondary,
    labels: {
      style: {
        fontSize: "12px",
        color: palette.text.secondary,
      },
    },
  },
  series: series.map(splineChartSeriesOptions),
  tooltip: {
    ...baseTooltipOptions,
    shared: true,
    xDateFormat: "%l:%M%P %a %e %b",
    headerFormat: `<span class="lineChartTooltipHeader">{point.key}</span>
        <table class="lineChartTooltipBody">`,
    pointFormat: `<tr>
          <td>
            <svg height="8" width="8">
              <circle cx="4" cy="4" r="4" fill="{series.color}" />
            </svg>
          </td>
          <td class="lineChartTooltipBodyName">{series.name}</td>
          <td class="lineChartTooltipBodyValue"><strong>{point.y}</strong></td>
        </tr>`,
    footerFormat: "</table>",
  },
});

export type LineChartSelectFn = (selection: LineChartSelection) => void;

const addSelectionToChartOptions = (
  options: Options,
  onSelect: LineChartSelectFn,
  palette: Palette
): Options => ({
  ...options,
  chart: {
    ...options.chart,
    zoomType: "x",
    selectionMarkerFill: `${palette.primary.main}1f`,
    events: {
      ...(!isNil(options.chart) && !isNil(options.chart.events)
        ? options.chart.events
        : undefined),
      selection(this: Chart, event): boolean | undefined {
        event.preventDefault();

        if (!isNil(event.xAxis)) {
          const { min, max } = event.xAxis[0];

          const selection: LineChartSelection = {
            min: !isNil(min) ? min : 0,
            max: !isNil(max) ? max : 0,
          };
          onSelect(selection);
        }

        return false;
      },
    },
  },
});

// TODO: Investigate why every second `data[]` array value is null in `labelFormatter()` method
// https://kasada.atlassian.net/browse/PPT-434
type SeriesWithYData = Series &
  Readonly<{
    yData?: number[];
  }>;

const addLegendToChartOptions = (
  options: Options,
  palette: Palette
): Options => ({
  ...options,
  legend: {
    enabled: true,
    align: "left",
    margin: 0,
    itemMarginBottom: 10,
    verticalAlign: "top",
    x: 51,
    y: -15,
    itemStyle: {
      color: palette.text.secondary,
      fontSize: "14px",
    },
    itemHoverStyle: {
      color: palette.text.primary,
    },
    itemHiddenStyle: {
      color: palette.text.disabled,
    },
    itemDistance: 40,
    symbolWidth: 0,
    symbolHeight: 0,
    symbolPadding: 0,
    useHTML: true,
    labelFormatter(this: Point | SeriesWithYData): string {
      // Only a Point if it's a Pie chart
      if (this instanceof Point) {
        return this.name;
      }

      // Ensure `yData` exists on Series
      if (isNil(this.yData)) {
        return this.name;
      }

      const seriesColor =
        this.visible && this.options.type === "spline"
          ? this.options.color
          : "rgba(255, 255, 255, 0.3)";

      const seriesTotal = humaniseNumberPortal(sum(this.yData), 1);

      const seriesLabel = `${this.name} ${seriesTotal}`;

      return `<svg width="16" height="16" style="vertical-align: top" data-analytics-category-component="Chart Filter" data-analytics-action="${
        this.name
      } Classification" data-analytics-label=${
        this.visible ? "Enabled" : "Disabled"
      }>
          <rect width="16" height="16" rx="2" ry="2" fill="${seriesColor}"/>
        </svg>
        <span style="margin-left: 8px" data-analytics-category-component="Chart Filter" data-analytics-action="${
          this.name
        } Classification" data-analytics-label=${
        this.visible ? "Enabled" : "Disabled"
      }>${seriesLabel} </span>`;
    },
  },
});

const lineChartOptions = (
  series: LineChartSeries[],
  legendVisible: boolean,
  palette: Palette,
  height?: number,
  yAxisLabel?: string,
  onSelect?: LineChartSelectFn
): Options => {
  const options = lineChartBaseOptions(series, palette, height, yAxisLabel);

  // TODO: Investigate why legend values are empty
  const optionsWithPossibleLegend = legendVisible
    ? addLegendToChartOptions(options, palette)
    : options;

  return !isNil(onSelect)
    ? addSelectionToChartOptions(optionsWithPossibleLegend, onSelect, palette)
    : optionsWithPossibleLegend;
};

export type LineChartSelection = Readonly<{
  min: number;
  max: number;
}>;

export type LineChartProps = Readonly<{
  series: LineChartSeries[];
  legendVisible?: boolean;
  height?: number;
  yAxisLabel?: string;
  onSelect?: LineChartSelectFn;
  className?: string;
}>;

export const LineChart: FC<LineChartProps> = ({
  series,
  legendVisible = false,
  height,
  yAxisLabel,
  onSelect,
  className,
}) => {
  const { palette } = useTheme();

  return (
    <HighchartsReact
      className={className}
      highcharts={Highcharts}
      containerProps={
        !isNil(onSelect)
          ? {
              "data-analytics-category-component": "Line Chart",
              "data-analytics-action": "Chart Section Highlighted",
              "data-analytics-label": "Custom Range",
            }
          : {}
      }
      options={lineChartOptions(
        series,
        legendVisible,
        palette,
        height,
        yAxisLabel,
        onSelect
      )}
    />
  );
};
