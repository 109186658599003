import {
  Box,
  Fab,
  FormControlLabel,
  Grid,
  ListSubheader,
  Paper,
  Switch,
  TextField, Typography,
} from '@material-ui/core';
import { Pause, PlayArrow } from '@material-ui/icons';
import React, { FC, useState } from 'react';
import { RequestTimelineDataPoint } from '../components/charts/TrafficOverviewChart';
import { useBotify } from './useBotify';

export type EnabledClassifications = {
  goodBot: boolean;
  badBot: boolean;
  human: boolean;
}

type BotifyTransportProps = Readonly<{
  requestTimeSeries: RequestTimelineDataPoint[];
}>;

export const BotifyV2: FC<BotifyTransportProps> = ({ requestTimeSeries }) => {
  const [enabledClassifications, setEnabledClassifications] = useState<EnabledClassifications>({
    goodBot: true,
    badBot: true,
    human: true,
  });

  const [bpm, setBPM] = useState(120);
  const [swingEnabled, setSwingEnabled] = useState(false);

  const { handleStart, handleStop, isPlaying } = useBotify(
    requestTimeSeries,
    bpm,
    false,
    enabledClassifications,
    swingEnabled,
  );

  const handlePlayPause = () => {
    if (isPlaying) {
      handleStop();
    } else {
      handleStart();
    }
  };

  const handleBPMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBPM(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBPMBlur = () => {
    if (bpm < 0) {
      setBPM(0);
    } else if (bpm > 300) {
      setBPM(300);
    }
  };

  const handleSwingToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwingEnabled(event.target.checked);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnabledClassifications({ ...enabledClassifications, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <ListSubheader disableSticky>Controls</ListSubheader>

      <Paper>
        <Box padding={2}>
          <Grid container spacing={2} alignItems="center" justify="space-between">
            <Grid item xs={4}>
              <FormControlLabel
                disabled={isPlaying}
                control={
                  <Switch
                    checked={enabledClassifications.goodBot}
                    onChange={handleChange}
                    name="goodBot"
                    color="primary"
                  />
                }
                labelPlacement="top"
                label={<Typography variant="caption">Good Bot</Typography>}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControlLabel
                disabled={isPlaying}
                control={
                  <Switch
                    checked={enabledClassifications.badBot}
                    onChange={handleChange}
                    name="badBot"
                    color="primary"
                  />
                }
                labelPlacement="top"
                label={<Typography variant="caption">Bad Bot</Typography>}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControlLabel
                disabled={isPlaying}
                control={
                  <Switch
                    checked={enabledClassifications.human}
                    onChange={handleChange}
                    name="human"
                    color="primary"
                  />
                }
                labelPlacement="top"
                label={<Typography variant="caption">Human</Typography>}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
                <TextField
                  value={bpm}
                  label="BotsPerMin"
                  margin="dense"
                  size="medium"
                  onChange={handleBPMChange}
                  onBlur={handleBPMBlur}
                  type="number"
                  variant="outlined"
                  disabled={isPlaying}
                  inputProps={{
                    step: 10,
                    min: 50,
                    max: 300,
                  }}
                />
            </Grid>

            <Grid item xs={6} sm={4}>
              <FormControlLabel
                disabled={isPlaying}
                control={
                  <Switch
                    checked={swingEnabled}
                    onChange={handleSwingToggle}
                    name="swing"
                    color="primary"
                  />
                }
                labelPlacement="top"
                label={<Typography variant="caption">Holiday mode</Typography>}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box width={1} display="flex" alignItems="center" justifyContent="center">
                <Fab onClick={handlePlayPause} color="primary">
                  {isPlaying ? <Pause/> : <PlayArrow/>}
                </Fab>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};
