import { ListItem, ListItemText } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { TrafficType } from '../../util/getTrafficData';

type SongListItemProps = {
  currentTrafficType: TrafficType;
  itemTrafficType: TrafficType;
  label: string;
  handleSelection: (trafficType: TrafficType) => void
  divider?: boolean;
}

export const SongListItem: FC<SongListItemProps> = ({
  currentTrafficType,
  itemTrafficType,
  label,
  handleSelection,
  divider,
}) => {
  const isSelected = useMemo(
    () => currentTrafficType === itemTrafficType,
    [currentTrafficType, itemTrafficType],
  );

  const handleChangeTrafficTypeList = (): void => {
    if (!isSelected) {
      handleSelection(itemTrafficType);
    }
  };

  return (
    <ListItem
      onClick={handleChangeTrafficTypeList}
      selected={isSelected}
      button
      divider={divider}
    >
      <ListItemText primary={label}/>
    </ListItem>
  );
};
