import { useEffect, useMemo, useRef, useState } from 'react';
import * as Tone from 'tone';
import { Chebyshev, Phaser, PingPongDelay, Reverb } from 'tone';
import { RequestTimelineDataPoint } from '../components/charts/TrafficOverviewChart';
import { EnabledClassifications } from './BotifyV2';
import { fixRequestSeriesData } from './utils/scaleHelpers';

type UseBotifyReturn = Readonly<{
  handleStart: VoidFunction;
  handleStop: VoidFunction;
  isPlaying: boolean;
}>

export const useBotify = (
  requestTimeSeries: RequestTimelineDataPoint[],
  bpm: number = 160,
  shouldRecord: boolean = false,
  enabledClassifications: EnabledClassifications,
  swingEnabled: boolean,
): UseBotifyReturn => {
  const [isPlaying, setIsPlaying] = useState(false);

  const sequences = useRef<Tone.Sequence>(null!);

  // Synths
  const synth = useRef<Tone.PolySynth>(null!);
  // const membraneSynth = useRef<MembraneSynth>(null!);

  const badBotSynth = useRef<Tone.FMSynth>(null!);
  const humanSynth = useRef<Tone.MonoSynth>(null!);
  const goodBotSynth = useRef<Tone.Synth>(null!);

  // FX
  const crusher = useRef<Tone.BitCrusher>(null!);
  const phaser = useRef<Phaser | null>(null);
  const cheby = useRef<Chebyshev | null>(null);
  const pingPong = useRef<PingPongDelay | null>(null);
  const reverb = useRef<Reverb | null>(null);

  // ToneJS util
  // const recorder = useRef<Tone.Recorder>(null!);

  // Map data to MIDI
  const newRequestTimeSeriesData = useMemo(
    () => fixRequestSeriesData(requestTimeSeries),
    [requestTimeSeries],
  );

  // Prepare Synths, FX, and Recorder
  useEffect(() => {
    // recorder.current = new Tone.Recorder();

    crusher.current = new Tone.BitCrusher(16).toDestination();
    phaser.current = new Phaser({
      frequency: 15,
      octaves: 5,
      baseFrequency: 1000,
    }).toDestination();
    cheby.current = new Chebyshev(50).toDestination();
    pingPong.current = new PingPongDelay('8n', 0.1).toDestination();
    reverb.current = new Reverb(3.5).toDestination();

    badBotSynth.current = new Tone.FMSynth()
      .connect(crusher.current)
      // .connect(recorder.current)
      .toDestination();

    goodBotSynth.current = new Tone.Synth({
      oscillator: {
        type: 'fatcustom',
        partials: [0.2, 1, 0, 0.5, 0.1],
        spread: 40,
        count: 3,
      },
      envelope: {
        attack: 0.001,
        decay: 1.6,
        sustain: 0,
        release: 1.6,
      },
      volume: 1,
    })
      // .connect(recorder.current)
      .toDestination();

    humanSynth.current = new Tone.MonoSynth({
        portamento: 0.01,
        oscillator: {
          type: 'sawtooth',
        },
        filter: {
          Q: 2,
          type: 'lowpass',
          rolloff: -24,
        },
        envelope: {
          attack: 0.1,
          decay: 0.1,
          sustain: 0.6,
          release: 0.5,
        },
        filterEnvelope: {
          attack: 0.05,
          decay: 0.8,
          sustain: 0.4,
          release: 1.5,
          baseFrequency: 2000,
          octaves: 1.5,
        },
        volume: -10,
      },
    )
      // .connect(recorder.current)
      .toDestination();

    synth.current = new Tone.PolySynth(Tone.Synth, {
      oscillator: {
        type: 'fatsawtooth',
        count: 3,
        spread: 30,
      },
      envelope: {
        attack: 0.01,
        decay: 0.1,
        sustain: 0.5,
        release: 0.4,
        attackCurve: 'exponential',
      },
    })
      // .connect(recorder.current)
      .toDestination();
  }, []);

  // Prepare sequences from data
  useEffect(
    () => {
      const subdivision = '8n';

      if (sequences.current) {
        sequences.current.clear();
      }

      sequences.current = new Tone.Sequence((
          (time, note) => {
            if (enabledClassifications.goodBot) {
              goodBotSynth.current.triggerAttackRelease(
                Tone.Frequency(note.classifications.goodBot, 'midi').toNote(),
                '104i',
                time,
              );
            }

            if (enabledClassifications.badBot) {
              badBotSynth.current.triggerAttackRelease(Tone.Frequency(
                note.classifications.badBot,
                'midi',
              )
                .toNote(), '8n', time);
            }

            if (enabledClassifications.human) {
              humanSynth.current.triggerAttackRelease(Tone.Frequency(note.classifications.human, 'midi')
                .toNote(), '2n', time);
            }
          }),
        newRequestTimeSeriesData, subdivision,
      ).start(0);
    },
    [
      newRequestTimeSeriesData,
      badBotSynth,
      goodBotSynth,
      humanSynth,
      sequences,
      enabledClassifications,
    ],
  );

  useEffect(() => {
    Tone.getTransport().bpm.rampTo(bpm, 1);
  }, [bpm]);

  const handleStart = async (): Promise<void> => {
    // Need to do this to avoid autoplay protection in chrome
    await Tone.start();

    Tone.getTransport().swing = swingEnabled ? 0.5 : 0;

    Tone.getTransport().bpm.value = bpm;

    // This starts the actual playback from the above sequences
    Tone.getTransport().start();

    setIsPlaying(true);

    // if (shouldRecord) {
    //   // This starts recording. Maybe we should make control this via a toggle in the UI?
    //   await recorder.current.start();
    // }
  };

  const handleStop = async (): Promise<void> => {
    // Stop the music
    Tone.getTransport().stop();

    setIsPlaying(false);

    // if (shouldRecord) {
    //   // Stop the recording
    //   const recording = await recorder.current.stop();
    //
    //   // Prepare the recording for download
    //   const url = URL.createObjectURL(recording);
    //   const anchor = document.createElement('a');
    //   anchor.download = `recording_${Date.now()}.webm`;
    //   anchor.href = url;
    //
    //   // Download the recording
    //   anchor.click();
    // }
  };

  return {
    handleStart,
    handleStop,
    isPlaying,
  };
};
