import { useTheme } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { VisualisationColours } from '../theme/palette';
import { LineChart, LineChartDataPoint, LineChartSeries } from './LineChart';
import { ChartLabels } from './utils';

export type RequestTimelineDataPoint = Readonly<{
  timestamp: string;
  total: number;
  classifications: {
    badBot: number;
    goodBot: number;
    human: number;
    whitelisted: number;
    unclassified: number;
  };
}>;

const toClassificationChartLines = (
  dataPoints: readonly RequestTimelineDataPoint[],
  visualisationColours: VisualisationColours,
): LineChartSeries[] => {
  const badBotsData: LineChartDataPoint[] = [];
  const goodBotsData: LineChartDataPoint[] = [];
  const humanData: LineChartDataPoint[] = [];

  dataPoints.forEach((metric) => {
    const x = DateTime.fromISO(metric.timestamp).valueOf();
    badBotsData.push({ x, y: metric.classifications.badBot });
    goodBotsData.push({ x, y: metric.classifications.goodBot });
    humanData.push({ x, y: metric.classifications.human });
  });

  return [
    {
      label: ChartLabels.BAD_BOT,
      color: visualisationColours.badBot,
      dataPoints: badBotsData,
      zIndex: 4,
    },
    {
      label: ChartLabels.HUMAN,
      color: visualisationColours.human,
      dataPoints: humanData,
      zIndex: 3,
    },
    {
      label: ChartLabels.GOOD_BOT,
      color: visualisationColours.goodBot,
      dataPoints: goodBotsData,
      zIndex: 2,
    },
  ];
};

type TrafficOverviewChartProps = Readonly<{
  requestTimeSeries: readonly RequestTimelineDataPoint[];
}>;

export const TrafficOverviewChart: FC<TrafficOverviewChartProps> = ({
  requestTimeSeries,
}) => {
  const {
    palette: { visualisation },
  } = useTheme();

  return (
    <LineChart
      yAxisLabel="Total Requests"
      series={toClassificationChartLines(requestTimeSeries, visualisation)}
      height={390}
      legendVisible
    />
  );
};
