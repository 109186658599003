import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createMuiTheme, { Theme } from "@material-ui/core/styles/createMuiTheme";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { Overrides } from "@material-ui/core/styles/overrides";
import { ComponentsProps } from "@material-ui/core/styles/props";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import { ShapeOptions } from "@material-ui/core/styles/shape";
import { unexpectedTypeError } from '../../util/unexpectedTypeError';
import { darkPalette, lightPalette } from "./palette";

const typography: TypographyOptions = {
  fontFamily: [
    "Lato",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Noto Sans",
    "Ubuntu",
    "Droid Sans",
    "Helvetica Neue",
    "sans-serif",
  ].join(", "),
  // Lato doesn't have a `medium` 500 variant, so we set the MUI fontWeightMedium to 700 which is Lato's `bold`
  fontWeightMedium: 700,
};

const props: ComponentsProps = {};

// Need access to breakpoints in order to do media queries in overrides
const breakpoints = createBreakpoints({});

const overrides: Overrides = {
  MuiToolbar: {
    regular: {
      minHeight: 64,
      [`${breakpoints.up("xs")} and (orientation: landscape)`]: {
        minHeight: 56,
      },
      [breakpoints.up("sm")]: {
        minHeight: 80,
      },
    },
  },
};

const shape: ShapeOptions = {
  borderRadius: 2,
};

const createKasadaTheme = (palette: PaletteOptions): Theme =>
  responsiveFontSizes(
    createMuiTheme({
      breakpoints,
      overrides,
      palette,
      props,
      shape,
      typography,
    })
  );

export const kasadaDarkTheme = createKasadaTheme(darkPalette);
export const kasadaLightTheme = createKasadaTheme(lightPalette);

export type PaletteVariant = "light" | "dark";

export const getTheme = (variant: PaletteVariant): Theme => {
  switch (variant) {
    case "light":
      return kasadaLightTheme;
    case "dark":
      return kasadaDarkTheme;
    default:
      throw unexpectedTypeError(variant, "PaletteVariant");
  }
};
